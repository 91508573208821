import { Injector, NgModule } from '@angular/core';
import { environment } from 'environments/environment';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { gripRoutes } from './app-routes';
import { ContentContainerComponent } from './components/content-container/content-container.component';
import { ThemeService, ThemeConfigService, DataService } from '@kpn-grip-fe/core';
import { StateService } from './shared/services/state.service';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { PortalSettingsService } from './shared/services/portal-settings.service';
import { SharedModule } from './shared/shared.module';
import { MenuService } from './shared/services/menu.service';
import { MenuDataService } from './shared/services/menu-data.service';
import { DigitalDataService } from './shared/services/digital-data.service';
import { MopinionService } from './shared/services/mopinion.service';
import { SquareComponent } from './components/square/square.component';
import { RoleComponent } from './components/role/role.component';
import { ErrorComponent } from './components/error/error.component';
import { ContainerComponent } from './components/container/container.component';
import { ClickOutsideDirective } from './shared/directives/click-outside/click-outside.directive';
import { HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { MenuApiInterceptor } from './shared/interceptors/menu-api-interceptor';
import { InlineSVGModule } from 'ng-inline-svg';
import { ProvisioningQueueComponent } from './components/provisioning-queue/provisioning-queue.component';
import { ProvisioningService } from './shared/services/provisioning.service';
import { UsersApiInterceptor } from './shared/interceptors/users-api-interceptor';
import { DigitalDataModel } from './shared/models/digital-data.model';
import { FaviconService } from './shared/services/favicon.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CustomTranslateLoader } from '@kpn-grip-fe/translations';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { LogTraceCookieService } from './shared/services/log-trace-cookie.service';
import { CookieService } from 'ngx-cookie-service';
import { NotificationsDataService } from './components/notifications/notifications-data.service';
import { MenuAuthInterceptor } from './shared/menu-auth.interceptor';

export function HttpLoaderFactory(inj: Injector): CustomTranslateLoader {
  return new CustomTranslateLoader(inj);
}

export interface GripWindow {
  state?: StateService;
  authenticationToken?: string;
}
declare global {
  interface Window {
    Grip: GripWindow;
    digitalData: DigitalDataModel;
  }
}

AppComponent.environmentConfig = environment;
DataService.apiUrl = AppComponent.environmentConfig.apiUrl;
DataService.showDialogOnUnhandledError = false;

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
    InlineSVGModule.forRoot(),
    RouterModule.forRoot(gripRoutes, {
      initialNavigation: 'disabled',
      relativeLinkResolution: 'legacy',
    }),
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [Injector],
      },
      isolate: true,
    }),
  ],
  declarations: [
    AppComponent,
    ClickOutsideDirective,
    ContainerComponent,
    ContentContainerComponent,
    ErrorComponent,
    HeaderComponent,
    MenuComponent,
    NotificationsComponent,
    ProvisioningQueueComponent,
    RoleComponent,
    SquareComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MenuApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UsersApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MenuAuthInterceptor,
      multi: true,
    },
    CookieService,
    DigitalDataService,
    FaviconService,
    LogTraceCookieService,
    MenuDataService,
    MenuService,
    MopinionService,
    NotificationsDataService,
    PortalSettingsService,
    ProvisioningService,
    StateService,
    ThemeConfigService,
    ThemeService,
  ],
  bootstrap: [AppComponent],
})
export class GripAppModule {}
