export enum DigitalDataErrorType {
  system = 'system',
  user = 'user',
}
export enum DigitalDataErrorSeverity {
  low = 'Low',
  medium = 'Medium',
  high = 'High',
}

export interface DigitalDataErrorModel {
  errorInfo: {
    typeOfError: DigitalDataErrorType;
  };
  attributes: {
    apiUrl?: string;
    code?: number;
    severity: DigitalDataErrorSeverity;
    message: string;
  };
}

export interface DigitalDataModel {
  page: {
    pageInfo: {
      pageName: string;
      destinationURL: string;
    };
    category: {
      primaryCategory: string;
    };
  };
  user: {
    profile: {
      profileInfo: {
        krnId: string;
        zmCustomerType: string;
        type: string;
      };
    };
  };
  error: DigitalDataErrorModel[];
}
