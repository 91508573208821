import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

import { DataService, HttpMethod, ImageService as CoreImageService } from '@kpn-grip-fe/core';
import { environment } from 'environments/environment';

export enum ImageTypes {
  Icon = 'icon',
  Screenshot = 'screenshot',
  StoreLogo = 'storelogo',
  DirectoryIcon = 'directoryicon',
}

export interface ImageObject {
  icon: string;
  title: string;
  url: URL | string;
  code?: string;
}

@Injectable()
export class ImageService {
  constructor(private readonly dataService: DataService) {}

  public static getIconPath(iconName: string): URL {
    return new URL(`${environment.cdnUrl}/images/icon/${iconName}_m.png?r=${Math.random()}`);
  }

  public static getScreenshotPath(screenshotName: string): URL {
    return new URL(`${environment.cdnUrl}/images/screenshot/${screenshotName}_d.png?r=${Math.random()}`);
  }

  public static getStoreLogoPath(logoName: string): URL {
    return new URL(`${environment.cdnUrl}/images/storelogo/${logoName}_m.png?r=${Math.random()}`);
  }

  public static getDirectoryIconPath(id: string): URL {
    return new URL(`${environment.cdnUrl}/images/directoryicon/${id}_dr.png?r=${Math.random()}`);
  }

  public static getMissingImageUrl(): string {
    return CoreImageService.getMissingImageUrl();
  }

  public static isBase64(input: string): boolean {
    return CoreImageService.isBase64(input);
  }

  // contentId is a name given by the back-end, might be a service ID for example
  public createImage(contentId: string, image: string, type: ImageTypes, customData: object = {}): Observable<object> {
    const scimSchema: string = 'urn:scim:schemas:extension:gripcontent:1.0';
    const data = {
      schemas: [scimSchema],
      [scimSchema]: {
        contentId,
        image,
        type,
      },
    };
    Object.assign(data[scimSchema], customData);

    return this.dataService.save('v3/content', HttpMethod.POST, data);
  }

  public deleteImage(contentId: string, type: ImageTypes): Observable<object> {
    if (type === ImageTypes.StoreLogo) {
      return this.dataService.delete(`v3/content/${contentId}/${type}/${contentId}`);
    }
    return this.dataService.delete(`v3/content/${contentId}/${type}`);
  }

  public extractBase64Images(imageCollection: ImageObject[]): ImageObject[] {
    return imageCollection.reduce((accumulator, current) => {
      if (ImageService.isBase64(current.icon)) {
        return accumulator.concat([Object.assign({}, current)]);
      }
      return accumulator;
    }, []);
  }

  public removeBase64Images(imageCollection: ImageObject[]): void {
    imageCollection.forEach((item) => {
      if (ImageService.isBase64(item.icon)) {
        item.icon = '';
      } else {
        item.icon = item.icon.toString().split('/').pop().split('_m.png')[0];
        item.code = item.icon.split('_').pop();
      }
    });
  }

  public prepareImages(items: ImageObject[], collectionItems: ImageObject[] = []): ImageObject[] {
    if (items && items.length) {
      collectionItems = collectionItems.map((iconItem) => {
        const newItem = items.find((item) => item.title === iconItem.title && item.url === iconItem.url);
        if (newItem) {
          newItem.icon = iconItem.icon;
        }
        return newItem;
      });
    }
    return collectionItems;
  }

  public createImageCalls(serviceId: string, items: ImageObject[]): Observable<object> {
    const imageCalls: Observable<object>[] = [];
    items.forEach((item) => {
      imageCalls.push(this.createImage(serviceId, item.icon, ImageTypes.Icon, { code: item.code }));
    });

    return forkJoin(imageCalls);
  }
}
