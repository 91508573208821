import { getQueryParameters } from '@grip/shared/utilities/get-query-parameters';
import { environment } from 'environments/environment';

export const API_CONSTANTS = {
  login: (domain: string, tenantShortName?: string, redirect?: string, deeplink?: string, prompt?: string): string =>
    `api/menu/v1/login${getQueryParameters(
      { key: 'domain', value: domain },
      { key: 'tenantShortName', value: tenantShortName },
      { key: 'redirect', value: redirect },
      { key: 'deeplink', value: deeplink },
      { key: 'prompt', value: prompt },
      { key: 'brand', value: new URLSearchParams(window.location.search).get('brand') }
    )}`,
  logout: (tenantId: string): string =>
    `api/menu/v1/logout${getQueryParameters({ key: 'tenantId', value: tenantId })}`,
  getGeneral: (tenantShortName: string): string =>
    `api/menu/api/v1/general${getQueryParameters({ key: 'tenantShortName', value: tenantShortName })}`,
  getMenu: (tenantShortName: string, targetTenantId?: string): string =>
    `api/menu/api/v1/menu${getQueryParameters(
      { key: 'tenantShortName', value: tenantShortName },
      { key: 'targetTenantId', value: targetTenantId }
    )}`,
  saveProfile: (): string => `${environment.bmsApiUrl}v3/settings/user`,
  saveUser: (): string => `${environment.userApiUrl}`,
  getNotifications: (): string => `v3/notifications`,
  updateNotification: (notificationId: string): string => `v3/notifications/${notificationId}`,
  getProfile: (): string => `${environment.userApiUrl}/api/users/v2/Users/Me`,
};
