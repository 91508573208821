import { Injectable } from '@angular/core';
import { API_CONSTANTS } from '@grip/shared/constants/api.constants';
import { APP_CONFIG, DataService, GripError, HttpMethod } from '@kpn-grip-fe/core';
import * as dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Notification {
  id: string;
  created: string;
  title: string;
  body: string;
  displayName: string;
  read: boolean;
}

@Injectable()
export class NotificationsDataService {
  constructor(private readonly dataService: DataService) {}

  public getNotifications(): Observable<Notification[]> {
    return this.dataService.get(API_CONSTANTS.getNotifications()).pipe(
      map((response: { 'urn:scim:schemas:extension:gripnotification:1.0': { notifications: Notification[] } }) => {
        const apiNotifications = response['urn:scim:schemas:extension:gripnotification:1.0']?.['notifications'];
        const notifications = apiNotifications.map(
          (apiNotification) =>
            ({
              id: apiNotification.id,
              created: dayjs(apiNotification.created).format(`${APP_CONFIG.dateFormat} ${APP_CONFIG.timeFormat}`),
              title: apiNotification.title,
              body: this.replaceBodyBbCodeWithHtml(apiNotification.body),
              displayName: apiNotification.displayName,
              read: apiNotification.read,
            } as Notification)
        );
        return notifications as Notification[];
      })
    );
  }

  public updateNotificationStatus(notificationId: string, read: boolean): Observable<object> {
    if (!notificationId || typeof read !== 'boolean') {
      throw new GripError('Could not update notification read status as input parameters were missing or malformed.');
    }
    return this.dataService.save(API_CONSTANTS.updateNotification(notificationId), HttpMethod.PATCH, {
      schemas: ['urn:scim:schemas:extension:gripnotification:1.0'],
      'urn:scim:schemas:extension:gripnotification:1.0': {
        read,
      },
    });
  }

  public deleteNotification(notificationId: string): Observable<object> {
    return this.dataService.delete(API_CONSTANTS.updateNotification(notificationId));
  }

  private replaceBodyBbCodeWithHtml(input: string): string {
    if (!input) {
      return input;
    }

    let output = input;

    // Replace [b] and [/b]
    output = output.replace(/\[b\]/gi, '<b>').replace(/\[\/b\]/gi, '</b>');

    // Replace [br]
    output = output.replace(/\[br\]/gi, '<br />');
    output = output.replace(/\[br\/\]/gi, '<br />');

    // Replace [a href="..."] ... [/a]
    output = output.replace(/\[a\s\shref/gi, '[a href');
    output = output.replace(/\[a href=\"(.*)\"\](.*)\[\/a\]/gi, '<a href="$1" target="_blank">$2</a>');

    // Replace [image] and [/image]
    output = output.replace(/\[image\](.*)\[\/image\]/gi, '<img src="$1" class="markupimage" />');

    return output;
  }
}
