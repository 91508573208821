<div class="app-layout" [class.app-layout--active-side-bar]="stateService.mobileMenuOpen">
  <grip-header></grip-header>
  <nav class="app-layout__side-bar side-bar">
    <grip-menu></grip-menu>
  </nav>

  <main class="app-layout__main">
    <div class="app-layout__title-bar title-bar theme__background--primary">
      <h1 class="title-bar__title">{{ pageTitle }}</h1>
    </div>

    <ng-content></ng-content>
  </main>
</div>
