import { NgModule } from '@angular/core';
import { CoreModule, DataService, PagedDataService, LocalizationService, GripMessageService, TitleService } from '@kpn-grip-fe/core';
import { GripNotificationModule } from '@kpn-grip-fe/notification';
import { GripDialogModule } from '@kpn-grip-fe/dialog';

import { ImageService } from '@grip/shared/services/image.service';
import { TranslationSetToken } from '@kpn-grip-fe/translations';
import { SHARED_TRANSLATIONS_EN } from './translations/shared.translations.en';
import { SHARED_TRANSLATIONS_NL } from './translations/shared.translations.nl';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { eagerLoad, EagerProviderLoaderModule } from 'ngx-inject';

@NgModule({
  imports: [
    CoreModule.addTranslations(),
    EagerProviderLoaderModule,
    FormsModule,
    GripDialogModule,
    GripNotificationModule,
    ReactiveFormsModule,
  ],
  exports: [CoreModule, FormsModule, GripNotificationModule, ReactiveFormsModule, TranslateModule],
  providers: [
    { provide: TranslationSetToken, useValue: SHARED_TRANSLATIONS_EN, multi: true },
    { provide: TranslationSetToken, useValue: SHARED_TRANSLATIONS_NL, multi: true },
    eagerLoad(LocalizationService),
    DataService,
    GripMessageService,
    ImageService,
    LocalizationService,
    PagedDataService,
    TitleService,
  ],
})
export class SharedModule {}
