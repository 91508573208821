import { Directive, OnInit, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Directive({
  selector: '[click-outside]',
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  @Output() public clickOutside: EventEmitter<void>;

  private listening: boolean;
  private globalClick: Subscription;

  constructor(private element: ElementRef) {
    this.listening = false;
    this.clickOutside = new EventEmitter();
  }

  public ngOnInit(): void {
    this.globalClick = fromEvent(document, 'click')
      .pipe(
        delay(1),
        tap(() => {
          this.listening = true;
        })
      )
      .subscribe((event: MouseEvent) => {
        this.onGlobalClick(event);
      });
  }

  public ngOnDestroy(): void {
    this.globalClick.unsubscribe();
  }

  public onGlobalClick(event: MouseEvent): void {
    if (event instanceof MouseEvent && this.listening === true) {
      if (!this.isDescendant(this.element.nativeElement, event.target) === true) {
        this.clickOutside.emit();
      }
    }
  }

  private isDescendant(parent: any, child: any): boolean {
    let node = child;
    while (node !== null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }
}
