import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuService } from '../../shared/services/menu.service';
import { MenuItemContainerModel, MenuItemType, MenuItemModel } from '@grip/shared/models/menu-item.model';
import { StateService } from '@grip/shared/services/state.service';
import { ThemeService } from '@kpn-grip-fe/core';

@Component({
  selector: 'grip-square',
  templateUrl: './square.component.html',
  styleUrls: ['./square.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SquareComponent implements OnInit, OnDestroy {
  public menuItemType = MenuItemType;
  public menuItem: MenuItemContainerModel;
  private subscriptions: Subscription = new Subscription();

  constructor(
    public readonly stateService: StateService,
    private readonly menuService: MenuService,
    private readonly themeService: ThemeService
  ) {}

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.menuService.activeMenuItem$.subscribe((item) => {
        this.menuItem = item as MenuItemContainerModel;
      })
    );
  }

  public isCustomIcon(icon: string): boolean {
    return icon && icon.startsWith('/');
  }

  public getRouterLink(item: MenuItemModel): string {
    return this.menuService.getRouterLink(item);
  }

  public getDarkerColor(color: string): string {
    const percentage = 20;
    let darkerColor: string = this.themeService.darkenColor(color.replace('#', ''), percentage);
    if (darkerColor.length < 7) {
      darkerColor = '#000000';
    }
    return darkerColor;
  }
}
