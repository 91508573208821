import { SHARED_TRANSLATIONS } from '@grip/shared/translations/shared.translations.keys';
import { defineTranslations } from '@kpn-grip-fe/translations';

/* tslint:disable:max-line-length */
export const SHARED_TRANSLATIONS_EN = defineTranslations<typeof SHARED_TRANSLATIONS>('en', {
  shared__save: 'Save',
  shared__cancel: 'Cancel',
  shared__and: 'and',
  shared__delete: 'Delete',
  shared__confirm: 'Confirm',
  shared__add: 'Add',
  shared__close: 'Close',
  shared__exit: 'Exit',
  shared__saveAndExit: 'Save and exit',
  shared__saveAndContinue: 'Save and continue',
  shared__continue: 'Continue',
  square__tileButtonLabel: 'Go',
  sideBar__close: 'Close',
  menuTrigger__label: 'Menu',

  error__pageTitle: 'Error',
  error__pageTitleNotFound: 'Not found',
  error__title: 'Oops!',
  error__subtitle: 'Something went wrong.',
  error__content: 'A technical error occured. Please contact your service desk if this problem persists.',
  error__contentNotFound: 'The page you requested could not be found. Perhaps it no longer exists or was moved.',
  error__contentManagementRights:
    'Could not handle the management request. Perhaps you have insufficient rights in the tenant/umbrella requested to perform this management.',
  error__back: 'Go back',
  error__knowledgeBase: 'Visit Knowledge Base',

  profile__pageTitle: 'Profile',
  profile__heading: 'Profile',
  profile__addAnotherAccount: 'Add another account',
  profile__link: 'My account',
  profile__logout: 'Log out',
  profile__changePassword: 'Change password',
  profile__nameLabel: 'Name',
  profile__lastLoginLabel: 'Previous login',
  profile__languageLabel: 'Language',
  profile__languageEnglish: 'English',
  profile__languageDutch: 'Dutch (Nederlands)',
  profile__success: 'Your profile settings have been updated successfully.',
  profile__noPreviousLogin: 'Not previously logged in.',
  profile__logTraceLabel: 'Support session tracing number',

  provisioning__title: 'User edit queue',
  provisioning__remove: 'Remove queue',
  provisioning__goToDeprovisioningQueue: 'To (de)provisioning queue',
  provisioning__deprovisioningQueueInfo:
    'To see more details, and debug any provisioning actions you might have taken, visit the (de)provisioning queue.',
  provisioning__executed: '{{callsToExecute}} user(s) to go',
  provisioning__succeeded: '{{succeeded}} user update(s) succeeded',
  provisioning__failed: '{{failed}} user update(s) failed',
  provisioning__cancel: 'Cancel',

  notifications__delete: 'Delete',
  notifications__backToList: 'Back to notification list',
  notifications__empty: 'There are no notifications for you.',
});
