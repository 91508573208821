import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GripError } from '@kpn-grip-fe/core';
import { Observable } from 'rxjs';

@Injectable()
export class MenuAuthInterceptor implements HttpInterceptor {
  public static apiUrl: string = '';
  public static withCredentials: boolean = true;
  public static bearerToken: string = '';
  public static manageTenantId: string = '';
  public static localDevelopment: boolean = false;

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!MenuAuthInterceptor.apiUrl) {
      throw new GripError('Could not apply AuthInterceptor: no apiUrl was set on the class.');
    }

    // Only add auth headers when contacting remote URI's
    if (!request.url.startsWith(MenuAuthInterceptor.apiUrl)) {
      return next.handle(request);
    }

    const headers = {
      'X-GRIP-Tenant-Id': '',
      Authorization: '',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
    };
    if (MenuAuthInterceptor.manageTenantId) {
      headers['X-GRIP-Tenant-Id'] = MenuAuthInterceptor.manageTenantId;
    }
    if (MenuAuthInterceptor.bearerToken) {
      headers['Authorization'] = `Bearer ${MenuAuthInterceptor.bearerToken}`;
    }

    const authRequest: HttpRequest<any> = request.clone({
      setHeaders: headers,
      withCredentials: MenuAuthInterceptor.withCredentials || false,
    });

    return next.handle(authRequest);
  }
}
