<div [innerHTML]="themeStyles"></div>

<div *ngIf="stateService.loading" class="progress-spinner__container progress-spinner__container--index">
  <div class="progress-spinner progress-spinner--index"></div>
</div>

<grip-error *ngIf="!showPage && stateService.blockingError"></grip-error>

<ng-container *ngIf="showPage">
  <grip-container>
    <router-outlet *ngIf="!stateService.blockingError"></router-outlet>
    <grip-error *ngIf="!!stateService.blockingError"></grip-error>
  </grip-container>
</ng-container>
