import { QueryParameterModel } from '../models/query-parameter.model';

export const getQueryParameters = (...parameters: QueryParameterModel[]): string => {
  let output = parameters && parameters.length ? '?' : '';
  parameters
    .filter((parameter) => !!parameter.value)
    .forEach((parameter, index, originalArray) => {
      output += `${parameter.key}=${parameter.value}`;
      if (index + 1 !== originalArray.length) {
        output += '&';
      }
    });
  return output;
};
