import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@grip/shared/services/state.service';
import { Subscription } from 'rxjs';
import { Notification, NotificationsDataService } from './notifications-data.service';

@Component({
  selector: 'grip-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  public loading: boolean = false;
  public notifications: Notification[];
  public highlightedNotification: Notification;
  public subscriptions: Subscription = new Subscription();

  constructor(public readonly stateService: StateService, private readonly notificationDataService: NotificationsDataService) {}

  public ngOnInit(): void {
    this.getNotifications();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public openNotification(notification?: Notification): void {
    if (!notification) {
      this.highlightedNotification = null;
      return;
    }
    this.highlightedNotification = notification;

    if (!notification.read) {
      this.subscriptions.add(
        this.notificationDataService.updateNotificationStatus(notification.id, true).subscribe(
          () => {
            notification.read = true;
          },
          (error) => {
            console.warn('Could not update notification status. The API error was: ', error);
          }
        )
      );
    }
  }

  public deleteNotification(notification: Notification): void {
    this.subscriptions.add(
      this.notificationDataService.deleteNotification(notification.id).subscribe(
        () => {
          this.notifications = this.notifications.filter((notificationInList) => notificationInList.id !== notification.id);
          this.openNotification(null);
        },
        (error) => {
          console.warn('Could not delete notification. The API error was: ', error);
        }
      )
    );
  }

  private getNotifications(): void {
    this.loading = true;
    this.subscriptions.add(
      this.notificationDataService.getNotifications().subscribe(
        (notifications) => {
          this.notifications = notifications;
          this.loading = false;
        },
        (error) => {
          console.warn('Could not retrieve notifications. The API error was: ', error);
        }
      )
    );
  }
}
