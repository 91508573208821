import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MenuItemContainerModel, MenuItemModel, MenuItemPageModel, MenuItemType } from '@grip/shared/models/menu-item.model';
import { Menu } from '@grip/shared/models/menu-type.model';
import { StateService } from '@grip/shared/services/state.service';
import { GripError, GripMessageService } from '@kpn-grip-fe/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { REDIRECT_ALIAS_CONSTANTS } from '../constants/redirect-alias.constants';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public activeMenu$: Observable<Menu>;
  public activeMenuItem$: Observable<MenuItemModel>;
  public menuList$: Observable<Menu[]>;
  public activeTabGuardFromMono: boolean = false;
  public activeTabGuardTriggerFromMono$ = new EventEmitter();

  private readonly fixedRoutes: string[] = ['profile'];
  private activeMenuSubject: BehaviorSubject<Menu> = new BehaviorSubject<Menu>(null);
  private activeMenuItemSubject: BehaviorSubject<MenuItemModel> = new BehaviorSubject<MenuItemModel>(null);
  private menuListSubject: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>(null);

  constructor(
    private readonly router: Router,
    private readonly stateService: StateService,
    private readonly translateService: TranslateService,
    private readonly gripMessageService: GripMessageService
  ) {}

  public get activeMenuItemBaseRoute(): string {
    return this.getRouterLink(this.activeMenuItemSubject.value);
  }

  public set activeMenu(menu: Menu) {
    if (!menu) {
      throw new GripError('Cannot set active menu, as no menu was given.');
    }
    if (
      menu &&
      menu.name &&
      this.activeMenuSubject.value &&
      this.activeMenuSubject.value.name &&
      menu.name === this.activeMenuSubject.value.name
    ) {
      return;
    }
    if (menu.labelId) {
      this.stateService.manageTenantId = menu.labelId;
    }
    this.activeMenuSubject.next(menu);
  }

  private set activeMenuItem(menuItem: MenuItemModel) {
    if (menuItem === this.activeMenuItemSubject.value) {
      return;
    }
    this.activeMenuItemSubject.next(menuItem);
  }

  public initialize(): void {
    this.activeMenu$ = this.activeMenuSubject.asObservable();
    this.activeMenuItem$ = this.activeMenuItemSubject.asObservable();
    this.menuList$ = this.menuListSubject.asObservable();
    this.activeMenu = this.stateService.menus[0];
    this.watchRouterEvents();
  }

  public refreshMenu(): void {
    const menus = this.stateService.menus;
    let activeUpdatedMenu = menus.find((menu) => menu.name === this.activeMenuSubject.value.name);
    if (!activeUpdatedMenu) {
      activeUpdatedMenu = this.activeMenuSubject.value;
    }
    const activeUpdatedMenuItem = activeUpdatedMenu.menuItems[0];

    this.menuListSubject.next(menus);
    this.activeMenuSubject.next(activeUpdatedMenu);
    if (activeUpdatedMenuItem) {
      this.activeMenuItemSubject.next(activeUpdatedMenuItem);
    }
  }

  public navigateSubRoute(routes: string[]): void {
    const menuItem: MenuItemPageModel = this.activeMenuItemSubject.value as MenuItemPageModel;
    if (!menuItem.page || !menuItem.page.baseRoute) {
      throw new GripError(
        `Cannot navigate to page, because no page information was given or page information was faulty. Check database config.`
      );
    }
    const pageBaseRoutes = menuItem.page.baseRoute.split('/');
    routes = routes.filter((route, index) => !(index === 0 && pageBaseRoutes[index] === route));
    routes.unshift(menuItem.route);
    const menuItemParent = this.getParent(menuItem);
    if (menuItemParent) {
      routes.unshift(menuItemParent.route);
    }

    const subRoutes = routes.filter((route) => !!route).join('/');
    const currentRoute = this.router.url;
    const targetRoute = `${this.stateService.baseRoute}/${subRoutes}`;
    if (currentRoute !== targetRoute) {
      this.router.navigateByUrl(targetRoute, { replaceUrl: true });
    }
    this.activeMenuItem = menuItem;
  }

  public getRouterLink(item: MenuItemModel): string {
    if (!item.route) {
      item.route = '';
    }
    let link: string;

    const isTopLevel = this.activeMenuSubject.value.menuItems.some((menuItem) => menuItem.route === item.route);
    if (isTopLevel) {
      link = `${item.route}`;
    } else {
      const parent = this.getParent(item);
      link = `${parent.route}/${item.route}`;
    }

    return `${this.stateService.baseRoute}/${link}`;
  }

  public getParent(item: MenuItemModel): MenuItemModel {
    return this.activeMenuSubject.value.menuItems.find((menuItem: MenuItemContainerModel) => {
      if (menuItem.menuItemType === MenuItemType.Container && menuItem.subMenu && menuItem.subMenu.length) {
        return menuItem.subMenu.some((child) => child.route === item.route);
      }
      return false;
    });
  }

  public setInitialIframeRoute(): void {
    if (
      this.activeMenuItemSubject.value &&
      this.activeMenuItemSubject.value.menuItemType === MenuItemType.Page &&
      !this.stateService.blockingError
    ) {
      this.gripMessageService.goToInitialRoute();
    }
  }

  public routeToFirstItemInMenu(callback?: (...args: any) => any): void {
    const activeMenu = this.activeMenuSubject ? this.activeMenuSubject.value : null;
    if (activeMenu) {
      this.router.navigateByUrl(`${this.stateService.baseRoute}/${activeMenu.menuItems[0].route}`).then(() => {
        if (callback) {
          callback();
        }
      });
    }
  }

  private watchRouterEvents(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.stateService.blockingError = null;
    });
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.handleRoute(event.urlAfterRedirects);
    });
  }

  private handleRoute(routeString: string): void {
    const routes = this.getSplitRoutes(routeString);
    if (routes.length === 0) {
      this.navigateToFirstMenuItemRoute();
      return;
    }

    const isFixedRoute = this.fixedRoutes.includes(routes.join('/'));
    const menu = this.findMenuByRoutes(routes);
    if (menu) {
      this.activeMenu = menu;
      const item = this.findMenuItemByRoutes(routes);
      this.activeMenuItem = item;
    } else if (isFixedRoute) {
      this.activeMenuItem = null;
      this.stateService.pageTitle = this.translateService.instant('profile__pageTitle');
    } else if (!!this.getRedirectAliasTargetRoute(routes)) {
      this.router.navigateByUrl(`${this.stateService.baseRoute}/${this.getRedirectAliasTargetRoute(routes)}`, { replaceUrl: true });
    } else {
      this.navigateToFirstMenuItemRoute();
    }
  }

  private findMenuByRoutes(routes: string[]): Menu {
    return this.stateService.menus.find((menu) => !!this.findMenuItemByRoutes(routes, menu));
  }

  private findMenuItemByRoutes(routes: string[], menu: Menu = this.activeMenuSubject.value): MenuItemModel {
    const topLevelItem = menu.menuItems.find((item) => item.route === routes[0]);
    if (topLevelItem && topLevelItem.menuItemType === MenuItemType.Container && routes.length > 1) {
      return (topLevelItem as MenuItemContainerModel).subMenu.find((subItem) => subItem.route === routes[1]);
    }
    return topLevelItem;
  }

  private getSplitRoutes(routeString: string): string[] {
    if (routeString.startsWith(this.stateService.baseRoute)) {
      routeString = routeString.replace(this.stateService.baseRoute, '');
    } else {
      routeString = routeString.replace('/', '');
    }
    const routes = routeString.split('/').splice(1);
    return routes;
  }

  private getRedirectAliasTargetRoute(routes: string[]): string {
    const redirectAliasItem = REDIRECT_ALIAS_CONSTANTS.find((item) => item.sourceRoute === routes[0]);
    return redirectAliasItem ? redirectAliasItem.destinationRoute : null;
  }

  private navigateToFirstMenuItemRoute(): void {
    const firstMenuItemRoute = this.stateService.menus[0].menuItems[0].route;
    this.router.navigateByUrl(`${this.stateService.baseRoute}/${firstMenuItemRoute}`);
  }
}
