import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MenuItemPageModel, MenuItemType } from '@grip/shared/models/menu-item.model';
import { QueueStatus, UserProvisioning } from '@grip/shared/models/user-provisioning.model';
import { MenuDataService } from '@grip/shared/services/menu-data.service';
import { ProvisioningService } from '@grip/shared/services/provisioning.service';
import { BlockingErrorType, StateService } from '@grip/shared/services/state.service';
import { GripError, GripMessageService } from '@kpn-grip-fe/core';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { MenuService } from '../../shared/services/menu.service';

@Component({
  selector: 'grip-content-container',
  templateUrl: './content-container.component.html',
  styleUrls: ['./content-container.component.scss'],
})
export class ContentContainerComponent implements OnInit, OnDestroy {
  @ViewChild('iframeElement') public $iframeElement: ElementRef;

  public isContainer: boolean;
  public contentUrl: SafeResourceUrl;
  private pageBaseUrl: string;
  private pageBaseRoute: string;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly gripMessageService: GripMessageService,
    private readonly menuService: MenuService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly stateService: StateService,
    private readonly router: Router,
    private readonly menuDataService: MenuDataService,
    private readonly provisioningService: ProvisioningService
  ) {}

  public ngOnInit(): void {
    const enableDebugMessages = localStorage.getItem('grip-dev-enable-message-event-debugging');
    if (!!enableDebugMessages || environment.enableMessageEventDebugging) {
      GripMessageService.logDebugMessages = true;
    }

    this.watchGripMessageServiceRouteChange();
    this.watchNavigationEvents();
    this.watchProvisoningQueue();
    this.watchActiveMenuItem();
    this.watchTabGuardActive();
    this.watchTabGuardTriggerFromMono();
    this.getProfile();
    window.addEventListener('message', this.sendMenuItem.bind(this));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getProfile(): void {
    this.subscriptions.add(
      this.menuDataService.getProfile().subscribe((profile) => {
        this.stateService.userId = profile.id;
      })
    );
  }

  private watchGripMessageServiceRouteChange(): void {
    this.subscriptions.add(
      this.gripMessageService.routeChange$.subscribe((routes: string[]) => {
        if (!routes || !routes.length) {
          return;
        }
        let routeString = routes.join('/').replace(this.pageBaseRoute, '');
        if (routeString.startsWith('/')) {
          routeString = routeString.replace('/', '');
        }
        const newRoute = routeString.split('/');

        this.menuService.navigateSubRoute(newRoute);
      })
    );
  }

  private watchTabGuardTriggerFromMono(): void {
    this.subscriptions.add(
      this.menuService.activeTabGuardTriggerFromMono$.subscribe(() => {
        const iframe = this.$iframeElement.nativeElement;
        const origin = this.getOrigin(this.pageBaseUrl);
        iframe.contentWindow.postMessage({ type: 'TabGuardOpeningFromMono', value: true }, origin);
      })
    );
  }
  private watchProvisoningQueue(): void {
    this.subscriptions.add(
      this.gripMessageService.provisioningQueue$.subscribe((userApiCalls: UserProvisioning) => {
        this.provisioningService.addCall(userApiCalls);
      })
    );

    this.subscriptions.add(
      this.provisioningService.sendMessageToIframe.subscribe((dataToSendBack: QueueStatus) => {
        const iframe = this.$iframeElement.nativeElement;
        const origin = this.getOrigin(this.pageBaseUrl);
        iframe.contentWindow.postMessage({ type: 'provisioning', dataToSendBack }, origin);
      })
    );
  }

  private sendMenuItem(item: MessageEvent): void {
    // eslint-disable-next-line no-console
    if (item.data.type === 'LoadFramePromise') {
      const iframe = this.$iframeElement.nativeElement;
      const origin = this.getOrigin(this.pageBaseUrl);
      iframe.contentWindow?.postMessage(
        {
          type: 'MenuItems',
          value: this.stateService.menus,
        },
        origin
      );
    }
  }

  private watchActiveMenuItem(): void {
    this.subscriptions.add(
      this.menuService.activeMenuItem$.subscribe((menuItem: MenuItemPageModel) => {
        if (!menuItem) {
          return;
        }
        this.isContainer = menuItem.menuItemType === MenuItemType.Container;
        if (this.isContainer) {
          this.gripMessageService.reset();
          this.pageBaseUrl = null;
          this.pageBaseRoute = null;
        } else {
          this.handleIframePage(menuItem);
        }
      })
    );
  }

  private watchNavigationEvents(): void {
    this.subscriptions.add(
      this.gripMessageService.navigateByUrl$.subscribe((url: string) => {
        if (url) {
          this.router.navigateByUrl(`${this.stateService.baseRoute}/${url}`);
        }
      })
    );
  }

  private watchTabGuardActive(): void {
    this.subscriptions.add(
      GripMessageService.tabGuardActive$.subscribe((isGuarded: boolean) => {
        this.stateService.tabGuardActive = !!isGuarded;
      })
    );
  }

  private handleIframePage(menuItem: MenuItemPageModel): void {
    if (!menuItem.page || !menuItem.page.baseRoute) {
      this.stateService.blockingError = BlockingErrorType.Generic;
      throw new GripError(
        `Cannot initialize iframe page, because no page information was given or page information was faulty. Check database config.`
      );
    }

    const newPageBaseUrl = menuItem.page.baseUrl;
    const newPageBaseRoute = menuItem.page.baseRoute;

    this.gripMessageService.removeEventListeners();

    this.pageBaseUrl = newPageBaseUrl;
    this.pageBaseRoute = newPageBaseRoute;

    const iframeUrl = this.pageBaseRoute === '/' ? `${this.pageBaseUrl}` : `${this.pageBaseUrl}/${this.pageBaseRoute}`;
    this.contentUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
    this.changeDetector.detectChanges();

    const origin = this.getOrigin(menuItem.page.baseUrl);

    let route;
    const routerUrl = this.router.url;
    const parentRoute = menuItem.parent ? menuItem.parent.route : null;
    const fullBaseRoute = [this.stateService.baseRoute, parentRoute, menuItem.route].filter((part) => !!part).join('/');
    if (routerUrl && routerUrl.startsWith(fullBaseRoute)) {
      route = routerUrl.replace(fullBaseRoute, `${menuItem.page.baseRoute}`);
      if (route.startsWith('/')) {
        route = route.replace('/', '');
      }
    } else {
      route = this.pageBaseRoute || '';
    }

    if (!this.stateService.authenticationToken) {
      throw new GripError('Cannot properly start iframe micro front-end, as no authentication token is present.');
    }

    this.gripMessageService.initializePage(
      this.$iframeElement.nativeElement as HTMLIFrameElement,
      this.pageBaseRoute || '',
      origin,
      {
        authenticationToken: this.stateService.authenticationToken,
        colorPrimary: this.stateService.tenant.colorPrimary,
        colorSecondary: this.stateService.tenant.colorSecondary,
        language: this.stateService.user.language,
        pageTitle: menuItem.title,
        tenantId: this.stateService.tenant.id,
        tenantShortName: this.stateService.tenant.shortName,
        organizationTypeId: this.stateService.tenant.organizationTypeId,
        manageTenantId: this.stateService.manageTenantId || 'false',
        functionalities: this.stateService.user?.functionalities || [],
        sessionId: this.stateService.sessionId,
        gripLogTrace: this.stateService.gripLogTrace,
      },
      route
    );
  }

  private getOrigin(baseUrl: string): string {
    // Some micro frontends currently run on our CDN gripstatic. Others run on the same URL. Either way, this should handle all scenarios we currently know.
    let origin: string;
    if (baseUrl.includes(environment.cdnUrl)) {
      origin = environment.cdnUrl;
    } else {
      origin = environment.portalUrl;
    }
    return origin;
  }
}
