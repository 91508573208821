import { z } from 'zod';

export const ZodUserSession = z
  .object({
    sessionId: z.string(),
    user: z.object({ name: z.string().nonempty() }),
    tenant: z
      .object({
        id: z.string(),
        shortName: z.string(),
        name: z.string(),
        logo: z.string(),
        icon: z.string(),
      })
      .required(),
    active: z.boolean(),
  })
  .strict();

export const ZodUserSessionArray = z.array(ZodUserSession);

export type ZodUserSessionArray = z.infer<typeof ZodUserSessionArray>;
export type ZodUserSession = z.infer<typeof ZodUserSession>;
