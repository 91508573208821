import { Injectable, OnDestroy } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import * as io from 'socket.io-client';
import { socketPath } from '../constants/socket.constants';
import { QueueStatus, SocketStatusMessage, UserError, UserProvisioning } from '../models/user-provisioning.model';
import { StateService } from './state.service';

@Injectable()
export class ProvisioningService implements OnDestroy {
  public provisioningError: Observable<any>;
  public provisioningQueueStatus: Observable<QueueStatus>;
  public doneCalling: Observable<boolean>;
  public progressPercentage: Observable<number> = new Observable<number>();
  public sendMessageToIframe: Observable<any> = new Observable<any>();

  private provisioningQueueStatus$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private provisioningError$: Subject<UserError> = new Subject<UserError>();
  private doneCalling$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private progressPercentage$: Subject<number> = new Subject<number>();
  private sendMessageBackToIframe$: Subject<any> = new Subject<any>();

  private subscriptions: Subscription = new Subscription();

  private socket: SocketIOClient.Socket;
  private hasActiveSocketConnection: boolean = false;

  constructor(private readonly stateService: StateService) {
    this.provisioningQueueStatus = this.provisioningQueueStatus$.asObservable();
    this.provisioningError = this.provisioningError$.asObservable().pipe(shareReplay(1));
    this.doneCalling = this.doneCalling$.asObservable();
    this.progressPercentage = this.progressPercentage$.asObservable();
    this.sendMessageToIframe = this.sendMessageBackToIframe$.asObservable();
    this.setupProvisioningSocketConnection();
  }

  public setupProvisioningSocketConnection(): void {
    if (!this.hasActiveSocketConnection) {
      this.socket = io(environment.apiUrl, {
        path: socketPath,
        forceNew: true,
        reconnectionAttempts: 100,
        timeout: 10000,
        transports: ['websocket'],
      });

      this.socket.on('connect', () => {
        this.subscribeToUserData();
      });

      this.socket.on('error', (error: UserError) => {
        this.handleSocketError(error);
      });

      this.socket.on('disconnect', () => {
        this.hasActiveSocketConnection = false;
      });
    }
  }

  public handleSocketError(error: UserError): void {
    this.provisioningError$.next(error);
    if (error?.errorDetail) {
      this.sendMessageBackToIframe$.next(error);
    }
  }

  public subscribeToUserData(): void {
    this.hasActiveSocketConnection = true;
    this.socket.on('userData', (data: QueueStatus | UserError) => {
      switch (data.statusMessage) {
        case SocketStatusMessage.QUEUE_STATUS:
          this.updateQueue(data as QueueStatus);
          break;
        case SocketStatusMessage.QUEUE_STARTED:
          this.updateQueue(data as QueueStatus);
          break;
        case SocketStatusMessage.ACCESS_TOKEN_INVALID:
        case SocketStatusMessage.INTERNAL_ERROR:
        case SocketStatusMessage.INVALID_PAYLOAD:
        case SocketStatusMessage.INVALID_PAYLOAD_PATCH_USER:
        case SocketStatusMessage.INVALID_TOPIC:
        case SocketStatusMessage.ERROR_PATCH:
          this.handleSocketError(data as UserError);
          break;
        default:
        // this.updateQueue(data as QueueStatus);
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public addCall(userApiCall: UserProvisioning): void {
    const { arrayOfData, payload }: UserProvisioning = userApiCall;
    const data = arrayOfData.map((userData) => ({
      payload: payload,
      userId: userData.id,
    }));

    this.socket.emit('userData', {
      event: userApiCall.action,
      data,
      headers: {
        'x-grip-session-tenant-id': this.stateService.tenant.id,
      },
    });
  }

  public resetState(): void {
    this.provisioningQueueStatus$.next({
      data: {},
    });
    this.provisioningError$.next();
  }

  private updateQueue(data: QueueStatus): void {
    this.provisioningQueueStatus$.next(data);
    this.sendMessageBackToIframe$.next(data);
  }
}
