import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogoutService } from '@kpn-grip-fe/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StateService } from '../services/state.service';

@Injectable()
export class UsersApiInterceptor implements HttpInterceptor {
  constructor(private readonly stateService: StateService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Only add auth headers when contacting remote URI's
    if (
      !request.url.startsWith(`${environment.userApiUrl}v3/users`) &&
      !request.url.startsWith(`${environment.userApiUrl}users/v2/Users`)
    ) {
      return next.handle(request);
    }

    const authHeader: string = `Bearer ${this.stateService.authenticationToken}`;
    const manageHeader: string = this.stateService.manageTenantId;
    const headers = {
      Authorization: authHeader,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      'X-GRIP-Tenant-Id': '',
    };

    if (manageHeader) {
      headers['X-GRIP-Tenant-Id'] = manageHeader;
    }

    const authRequest: HttpRequest<any> = request.clone({
      setHeaders: headers,
      withCredentials: true,
    });

    return next.handle(authRequest).pipe(
      tap(null, (error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          LogoutService.logout$.next();
        }
      })
    );
  }
}
