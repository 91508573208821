import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { deepClone, Language, ThemeConfigService } from '@kpn-grip-fe/core';
import { TranslateService } from '@ngx-translate/core';
import { Menu } from '../models/menu-type.model';
import { PortalSettingsModel } from '../models/portal-settings.model';
import { PreferredLanguage } from '../models/preferred-language.model';
import { TenantModel } from '../models/tenant.model';
import { UserSession } from '../models/user-session.model';
import { UserModel } from '../models/user.model';
import { ZodUserSession, ZodUserSessionArray } from '../zod-validators/session.validator';

export enum BlockingErrorType {
  NotFound = 'NotFound',
  Generic = 'Generic',
  ManagementRights = 'ManagementRights',
}

@Injectable()
export class StateService {
  public sessionId: string;
  public authenticationToken: string;
  public manageTenantId: string;
  public mobileMenuOpen: boolean = false;
  public profileMenuOpen: boolean = false;
  public loading: boolean = false;
  public blockingError: BlockingErrorType = null;
  public gripLogTrace: string;
  public tabGuardActive: boolean = false;
  public tenantShortName: string;
  private _user: UserModel = {
    displayName: '',
    language: this.translateService.currentLang as Language,
  };
  private _userId: string;
  private _pageTitle: string;
  private _portalSettings: PortalSettingsModel;
  private _menus: Menu[];

  constructor(private readonly titleService: Title, private readonly router: Router, private readonly translateService: TranslateService) {}

  public get baseRoute(): string {
    return `/a/${this.tenantShortName}`;
  }

  public get portalSettings(): PortalSettingsModel {
    return deepClone(this._portalSettings);
  }

  public set portalSettings(settings: PortalSettingsModel) {
    this.user = settings.user;
    this.authenticationToken = settings.accessToken;
    this.sessionId = settings.sessionId;
    delete settings.user;
    this._portalSettings = deepClone(settings);
    this.tenant = settings.theme;
    localStorage.setItem('tenantId', this.tenant.id);
    sessionStorage.setItem('tenantId', this.tenant.id);
  }

  public addSessionToSessionList(newSession: ZodUserSession, sessionList: ZodUserSessionArray): void {
    const sessionListToStore = sessionList.map((session: ZodUserSession) => ({ ...session, active: false } as ZodUserSession));
    const sessionIncluded = sessionListToStore.find((session: ZodUserSession) => session.tenant.id === newSession.tenant.id);
    if (!sessionIncluded) {
      sessionListToStore.push(newSession);
    } else {
      Object.assign(sessionIncluded, newSession);
    }
    localStorage.setItem('sessions', JSON.stringify(sessionListToStore));
  }

  public removeSession(): void {
    const sessions = this.getCurrentSessions();
    const sessionList = sessions.filter((session: UserSession) => session.tenant.id !== this.tenant.id);
    localStorage.setItem('sessions', JSON.stringify(sessionList));
  }

  public recordActiveSessions(): void {
    const sessionList = this.getCurrentSessions();
    const newSession: ZodUserSession = {
      sessionId: this.sessionId,
      user: { name: this.user.displayName },
      tenant: { ...this.tenant },
      active: true,
    };
    if (sessionList.length) {
      this.addSessionToSessionList(newSession, sessionList);
    } else {
      const singleSession = [newSession];
      localStorage.setItem('sessions', JSON.stringify(singleSession));
    }
  }

  public getCurrentSessions(): ZodUserSessionArray {
    try {
      const session = JSON.parse(localStorage.getItem('sessions'));
      if (!ZodUserSessionArray.safeParse(session).success) {
        return [];
      }
      return session;
    } catch (e) {
      return [];
    }
  }

  public get menuTypeNames(): string[] {
    return this.menus.map((menu) => menu.name);
  }

  public get menus(): Menu[] {
    return deepClone(this._menus);
  }

  public set menus(menus: Menu[]) {
    this._menus = deepClone(menus);
  }

  public get pageTitle(): string {
    return this._pageTitle;
  }

  public set pageTitle(title: string) {
    this._pageTitle = title;
    let newTitle = this.tenant && this.tenant.organizationType ? this.tenant.organizationType : 'Grip';
    if (title) {
      newTitle += ` | ${this._pageTitle}`;
    }
    this.titleService.setTitle(newTitle);
  }

  public get tenant(): TenantModel {
    return deepClone(this._portalSettings ? this._portalSettings.theme : null);
  }

  public set tenant(tenant: TenantModel) {
    this._portalSettings.theme = deepClone(tenant);
    ThemeConfigService.colorPrimary = this.tenant.colorPrimary;
    ThemeConfigService.colorSecondary = this.tenant.colorSecondary;
  }

  public get user(): UserModel {
    return deepClone(this._user);
  }

  public set user(user: UserModel) {
    this._user = Object.assign(this._user, deepClone(user));
    this.preferredLanguage = this._user.preferredLanguage;
  }

  public set preferredLanguage(preferredLanguage: PreferredLanguage) {
    if (!preferredLanguage) {
      return;
    }
    const language = preferredLanguage === 'nl-NL' ? Language.Dutch : Language.English;
    this._user.language = language;
    this._user.preferredLanguage = preferredLanguage;
    this.translateService.use(language);
    localStorage.setItem('language', language);
  }

  public get userId(): string {
    return this._userId;
  }

  public set userId(userId: string) {
    this._userId = userId;
  }

  public navigateHome(): void {
    this.router.navigateByUrl('/');
  }
}
