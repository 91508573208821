import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_CONSTANTS } from '@grip/shared/constants/api.constants';
import { MenuItemContainerModel, MenuItemModel, MenuItemPageModel, MenuItemType } from '@grip/shared/models/menu-item.model';
import { Menu } from '@grip/shared/models/menu-type.model';
import { StateService } from '@grip/shared/services/state.service';
import { DataService, Language } from '@kpn-grip-fe/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserModel } from '../models/user.model';

@Injectable()
export class MenuDataService {
  constructor(
    private readonly dataService: DataService,
    private readonly stateService: StateService,
    private readonly httpClient: HttpClient
  ) {}

  public get logoutUrl(): string {
    const tenantId = sessionStorage.getItem('tenantId');
    return `${window.location.origin}/${API_CONSTANTS.logout(tenantId)}`;
  }

  public doLogin(redirect?: string, deeplink?: string, prompt?: string): void {
    window.location.href = `${window.location.origin}/${API_CONSTANTS.login(
      window.location.origin,
      this.stateService.tenantShortName,
      redirect,
      deeplink,
      prompt
    )}`;
  }

  public getMenu(targetTenantId?: string): Observable<Menu[]> {
    return this.dataService.get(API_CONSTANTS.getMenu(this.stateService.tenantShortName, targetTenantId)).pipe(
      map((apiMenus) => {
        let menus = apiMenus as Menu[];
        menus = menus.filter((menu) => menu.menuItems && menu.menuItems.length);
        menus = menus.map((menu) => {
          menu.menuItems = this.filterEmptyContainerMenuItems(menu.menuItems);
          menu.menuItems = this.mapMenuItems(menu.menuItems);
          return menu;
        });
        this.stateService.menus = menus;
        return menus;
      })
    );
  }

  public saveProfile(language: Language): Observable<unknown> {
    const preferredLanguage = language === Language.Dutch ? 'nl-NL' : 'en-US';
    return this.httpClient.patch<unknown>(
      API_CONSTANTS.saveProfile(),
      {
        schemas: ['urn:scim:schemas:extension:gripuser:1.0'],
        'urn:scim:schemas:extension:gripuser:1.0': {
          preferredLanguage,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${this.stateService.authenticationToken}`,
        },
        withCredentials: true,
      }
    );
  }

  public getProfile(): Observable<UserModel> {
    return this.httpClient.get(API_CONSTANTS.getProfile(), {
      headers: {
        'x-grip-session-tenant-id': this.stateService.tenant.id,
      },
    });
  }

  private mapMenuItems(menuItems: MenuItemModel[], parentToAdd?: MenuItemModel): MenuItemModel[] {
    return menuItems.map((menuItem) => {
      menuItem = this.prependPortalUrlToMenuItemOnLocal(menuItem);
      if (parentToAdd) {
        menuItem.parent = parentToAdd;
      }

      const menuItemContainer = menuItem as MenuItemContainerModel;
      if (menuItemContainer.subMenu) {
        menuItemContainer.subMenu = this.mapMenuItems(menuItemContainer.subMenu, menuItemContainer);
        menuItem = menuItemContainer;
      }
      return menuItem;
    });
  }

  private filterEmptyContainerMenuItems(menuItems: MenuItemModel[]): MenuItemModel[] {
    return menuItems.filter((menuItem) => {
      if (menuItem.menuItemType === MenuItemType.Container) {
        const containerItem = menuItem as MenuItemContainerModel;
        return containerItem.subMenu && containerItem.subMenu.length;
      }
      return true;
    });
  }

  /**
   * On local environments, relative paths to micro frontends will not work, because they are probably not running on your local machine.
   * This method prepends the portal URL to those pages. Example: /gst/grip-hom-fe/homepage => https://www.tst.onkpn.com/gst/grip-hom-fe/homepage
   */
  private prependPortalUrlToMenuItemOnLocal(menuItem: MenuItemModel): MenuItemModel {
    const pageMenuItem = menuItem as MenuItemPageModel;
    if (
      environment.localDevelopment &&
      pageMenuItem.menuItemType === MenuItemType.Page &&
      pageMenuItem.page &&
      pageMenuItem.page.baseUrl &&
      pageMenuItem.page.baseUrl.startsWith('/')
    ) {
      pageMenuItem.page.baseUrl = `${environment.portalUrl}${pageMenuItem.page.baseUrl}`;
      menuItem = pageMenuItem as MenuItemModel;
    }
    return menuItem;
  }
}
