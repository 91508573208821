import { Injectable } from '@angular/core';
import { StateService } from './state.service';

declare class Pastease {
  public static load(id: string): void;
}

@Injectable()
export class MopinionService {
  private readonly whitelistedOrganizationTypeIds: string[] = ['1', '3'];

  constructor(private readonly stateService: StateService) {}

  public initialize(): void {
    if (!this.stateService.tenant.organizationTypeId) {
      return;
    }
    if (this.whitelistedOrganizationTypeIds.includes(this.stateService.tenant.organizationTypeId)) {
      this.loadMopinion();
    }
  }

  private loadMopinion(): void {
    const id = 't0pi3wlh88ecuq1tz543mbfovxhbnc3lqsm';
    const js = document.createElement('script');
    js.setAttribute('type', 'text/javascript');
    js.setAttribute('src', '//deploy.mopinion.com/js/pastease.js');
    js.async = true;
    document.getElementsByTagName('head')[0].appendChild(js);
    const t = setInterval(() => {
      try {
        Pastease.load(id);
        clearInterval(t);
      } catch (e) {}
    }, 50);
  }
}
