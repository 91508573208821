export enum MenuItemType {
  Container = 'container',
  Page = 'page',
  Url = 'url',
}

export interface MenuItemModel {
  id?: string;
  parentId?: string;
  order?: number;
  menuItemType: MenuItemType;
  /** The route for the menu item, to show in the URL bar. No slashes please. E.g. "reporting", "audit", "services", "invoices". */
  route: string;
  /** The page title and label to show in the menu. */
  title: string;
  /** The subtitle to show on the square tile. */
  subtitle?: string;
  /** The label to show on the button on the square tile. */
  buttonLabel: string;
  /** Menu UI icon name, base64 image, or image URL. (https://style.tools.onkpn.com/doc/base/ui-icon) */
  icon: string;
  parent?: MenuItemModel;
  /** Addon to show in menu or on the tile (e.g. "BETA") */
  addon?: string;
}

/** For MenuItemType.Page */
export interface MenuItemPageModel extends MenuItemModel {
  page: {
    /** The URL where the page can be found, e.g. "https://www.gripstatic.com/adm" */
    baseUrl: string;
    /** The base URL inside of the application where the page can be found, e.g. "services". Leave empty/undefined if using the baseUrl as root. */
    baseRoute?: string;
  };
}

/** For MenuItemType.Url */
export interface MenuItemUrlModel extends MenuItemModel {
  url: string;
}

/** For MenuItemType.Container */
export interface MenuItemContainerModel extends MenuItemModel {
  subMenu: MenuItemModel[];
}
