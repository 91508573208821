<div class="container">
  <div class="row tile-square">
    <div *ngFor="let item of menuItem.subMenu; let i = index" class="col col--xs-12 col--s-6 col--l-4">
      <div class="tile">
        <div *ngIf="item.addon" class="tile__addon">
          <div class="tile__addon-ribbon">
            <svg width="101" height="102" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                <path
                  [attr.fill]="this.getDarkerColor(stateService.tenant.colorPrimary)"
                  d="M4.592437 0C-.704403.008748.301588 4.818898.301588 4.818898h20.84127S16.470423 0 4.592437 0zM100.5 97.406428c-.008732 5.64029-4.809524 4.498803-4.809524 4.498803V80.31496S100.5 85.17656 100.5 97.406428z"
                />
                <path
                  opacity=".01"
                  d="M56.379447 12.064175C75.546616 31.377934 83.45276 39.257814 88.42317 44.571763c5.291917 4.914288 6.809153 10.112464 6.866513 10.265554v32.304415L12.72619 5.220472h32.781278c.1536.05717 5.853842 2.052497 10.871977 6.843703z"
                  fill="#000"
                />
                <path
                  [attr.fill]="stateService.tenant.colorPrimary"
                  d="M60.082076 6.185749c20.513186 20.948318 28.974538 29.495087 34.293996 35.258766 5.663542 5.330194 6.06254 10.968301 6.123928 11.134347v47.01169s-.408277-9.137535-8.573499-17.938671L19.051761 8.660048C10.731651.190862 1.904761 0 1.904761 0h46.541852c.164387.062008 6.264928.989053 11.635462 6.185749z"
                />
              </g>
            </svg>
          </div>
          <span class="tile__addon-text">{{ item.addon }}</span>
        </div>
        <div class="tile__content">
          <i *ngIf="item.icon?.length && !isCustomIcon(item.icon)" class="{{ item.icon }} theme__color--primary tile__icon"></i>
          <div
            *ngIf="item.icon?.length && isCustomIcon(item.icon)"
            class="theme__color--primary tile__icon tile__icon--custom"
            [inlineSVG]="item.icon"
          ></div>
          <h3 class="tile__heading">{{ item.title }}</h3>
          <span *ngIf="item.subtitle" class="tile__descriptions">
            {{ item.subtitle }}
          </span>
          <div class="tile__action">
            <button
              [attr.data-testid]="'RWYCIABU0I-' + i"
              *ngIf="item.menuItemType !== menuItemType.Url"
              [routerLink]="getRouterLink(item)"
              class="button button--secondary"
              type="button"
            >
              {{ item.buttonLabel || ('square__tileButtonLabel' | translate) }}
            </button>

            <a
              *ngIf="item.menuItemType === menuItemType.Url"
              target="_blank"
              [href]="item.url"
              class="button button--secondary"
              [attr.data-testid]="'RWYCIA80GG-' + i"
            >
              <i class="ui-external-link"></i>
              {{ item.buttonLabel || ('square__tileButtonLabel' | translate) }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
