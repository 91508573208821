import { Routes } from '@angular/router';
import { ContentContainerComponent } from './components/content-container/content-container.component';
import { NotificationsComponent } from './components/notifications/notifications.component';

export const gripRoutes: Routes = [
  {
    path: 'a/:tenantShortName/notifications',
    component: NotificationsComponent,
  },
  {
    path: '**',
    component: ContentContainerComponent,
  },
];
