import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { StateService } from '@grip/shared/services/state.service';
import { ProvisioningService } from '@grip/shared/services/provisioning.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { QueueStatus } from '@grip/shared/models/user-provisioning.model';

@Component({
  selector: 'grip-provisioning-queue',
  templateUrl: './provisioning-queue.component.html',
  styleUrls: ['./provisioning-queue.scss'],
})
export class ProvisioningQueueComponent implements OnInit, OnDestroy {
  @Output() public closeSelf: EventEmitter<void> = new EventEmitter<void>();
  public totalAmountOfCalls: number = 0;
  public totalAmountOfCallsExecuted: number = 0;
  public totalAmountOfCallsFailed: number = 0;
  public callsToExecute: number = 0;
  public totalAmountOfCallsSucceeded: number = 0;
  public percentage: number = 0;
  public doneExecuting: boolean = false;
  private readonly deprovisioningQueueRoute: string = 'deprovisioning-queue';
  private subscription: Subscription = new Subscription();

  constructor(
    public readonly stateService: StateService,
    public readonly provisioningService: ProvisioningService,
    private readonly router: Router
  ) {}

  public get callsTranslationData(): object {
    return {
      callsToExecute: this.callsToExecute,
      executed: this.totalAmountOfCallsExecuted,
      total: this.totalAmountOfCalls,
      succeeded: this.totalAmountOfCallsSucceeded,
      failed: this.totalAmountOfCallsFailed,
    };
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.provisioningService.provisioningQueueStatus.subscribe((queue: QueueStatus) => {
        if (!queue?.data) {
          return;
        }
        this.totalAmountOfCalls = queue.data.totalCalls;
        this.totalAmountOfCallsExecuted = queue.data.failed + queue.data.success;
        this.totalAmountOfCallsFailed = queue.data.failed;
        this.totalAmountOfCallsSucceeded = queue.data.success;
        this.callsToExecute = queue.data.totalCalls - this.totalAmountOfCallsExecuted;
        this.percentage = Math.floor((this.totalAmountOfCallsExecuted / this.totalAmountOfCalls) * 100);
      })
    );

    this.subscription.add(
      this.provisioningService.doneCalling.subscribe((done) => {
        this.doneExecuting = done;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public resetProvisioningQueue(): void {
    this.doneExecuting = false;
    this.provisioningService.resetState();
    this.closeSelf.emit();
  }
  public goToDeprovisioningQueue(): void {
    this.router.navigateByUrl(`${this.stateService.baseRoute}/${this.deprovisioningQueueRoute}`);
    this.closeSelf.emit();
  }
}
