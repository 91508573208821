import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { tap } from 'rxjs/operators';
import { LogoutService } from '@kpn-grip-fe/core';

@Injectable()
export class MenuApiInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Only add auth headers when contacting remote URI's
    if (!request.url.startsWith(environment.apiUrl)) {
      return next.handle(request);
    }

    const headers = {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
    };

    const authRequest: HttpRequest<any> = request.clone({
      setHeaders: headers,
      withCredentials: true,
    });

    return next.handle(authRequest).pipe(
      tap(null, (error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          LogoutService.logout$.next();
        }
      })
    );
  }
}
