<div class="card top-bar__flyout provisioning-queue">
  <h2 class="h2 margin-bottom-2">{{ 'provisioning__title' | translate }}</h2>

  <div class="progress-bar provisioning-queue__progress">
    <div class="progress-bar__meter">
      <div class="progress-bar__value theme__background--primary" [style.width]="percentage.toString() + '%'"></div>
    </div>
    <div class="progress-bar__text">{{ percentage }}%</div>
  </div>

  <ul class="provisioning-queue__checklist">
    <li>
      <i class="ui-renew provisioning-queue__checklist-icon provisioning-queue__checklist-icon--neutral"></i>
      <span>{{ 'provisioning__executed' | translate : callsTranslationData }}</span>
    </li>
    <li>
      <i class="ui-check provisioning-queue__checklist-icon provisioning-queue__checklist-icon--success"></i>
      <span>{{ 'provisioning__succeeded' | translate : callsTranslationData }}</span>
    </li>
    <li>
      <i class="ui-error provisioning-queue__checklist-icon provisioning-queue__checklist-icon--failed"></i>
      <span>{{ 'provisioning__failed' | translate : callsTranslationData }}</span>
    </li>
  </ul>

  <p class="p p--small provisioning-queue__help-text">{{ 'provisioning__deprovisioningQueueInfo' | translate }}</p>
  <button class="button button--primary button--fluid" data-testid="RWYCIAM5FM" type="button" (click)="goToDeprovisioningQueue()">
    {{ 'provisioning__goToDeprovisioningQueue' | translate }}
  </button>
  <button
    class="button button--secondary button--fluid margin-top-2"
    data-testid="RWYCIAB56Q"
    type="button"
    (click)="resetProvisioningQueue()"
  >
    {{ 'provisioning__remove' | translate }}
  </button>
</div>
