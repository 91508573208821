import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Menu } from '@grip/shared/models/menu-type.model';
import { Subscription } from 'rxjs';
import { MenuService } from '@grip/shared/services/menu.service';
import { StateService, BlockingErrorType } from '@grip/shared/services/state.service';
import {
  GripMessageService,
  deepClone,
  GripError,
  GripManageTenantConfig,
  SessionData,
  SessionDataType,
  SessionManageDataType,
} from '@kpn-grip-fe/core';
import { MenuDataService } from '@grip/shared/services/menu-data.service';
import { DigitalDataService } from '@grip/shared/services/digital-data.service';

@Component({
  selector: 'grip-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
})
export class RoleComponent implements OnInit, OnDestroy {
  public dropdownOpen: boolean = false;
  public options: Menu[] = this.stateService.menus;
  public activeOption: Menu;
  private subscriptions: Subscription = new Subscription();
  private realActiveMenu: Menu;
  private managementMenu: Menu;
  private tabGuardedPendingRole: Menu = null;

  constructor(
    public readonly digitalDataService: DigitalDataService,
    private readonly stateService: StateService,
    private readonly menuService: MenuService,
    private readonly gripMessageService: GripMessageService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly menuDataService: MenuDataService
  ) {}

  public ngOnInit(): void {
    this.clearLocalManagement();
    this.watchMangementInstructions();

    this.subscriptions.add(
      this.menuService.activeMenu$.subscribe((menu) => {
        this.activeOption = deepClone(menu);
        this.realActiveMenu = deepClone(menu);
      })
    );

    this.subscriptions.add(
      this.menuService.menuList$.subscribe((menus) => {
        this.options = menus || this.stateService.menus;
      })
    );

    this.subscriptions.add(
      GripMessageService.tabGuardDone$.subscribe(() => {
        this.stateService.tabGuardActive = false;
        if (this.tabGuardedPendingRole) {
          this.selectOption(this.tabGuardedPendingRole);
        }
        this.tabGuardedPendingRole = null;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public selectOption(role: Menu): void {
    this.dropdownOpen = false;
    this.stateService.mobileMenuOpen = false;
    if (this.stateService.tabGuardActive) {
      GripMessageService.triggerTabGuard$.next();
      this.tabGuardedPendingRole = role;
      return;
    }
    this.menuService.activeMenu = role;
    if (role.labelId) {
      this.menuService.routeToFirstItemInMenu();
    } else {
      this.clearManagement(() => {
        this.menuService.activeMenu = role;
        this.menuService.routeToFirstItemInMenu();
      });
    }
  }

  private watchMangementInstructions(): void {
    this.subscriptions.add(
      this.gripMessageService.manageTenant$.subscribe((instructions) => {
        if (instructions === null) {
          this.clearManagement();
        } else {
          this.manage(instructions);
        }
        this.changeDetector.detectChanges();
      })
    );
  }

  private manage(instructions: GripManageTenantConfig): void {
    if (!instructions || !instructions.id) {
      throw new GripError('Tried to manage, but instructions were malformed or not present.');
    }
    instructions.id = String(instructions.id);
    SessionData.set(SessionManageDataType.manageTenant, instructions);
    this.subscriptions.add(
      this.menuDataService.getMenu(instructions.id).subscribe(() => {
        this.stateService.manageTenantId = instructions.id;
        this.menuService.refreshMenu();
        const isUmbrella = instructions.id && instructions.id.startsWith('u');
        const menuForManagement: Menu = this.options.find((option) => {
          if (isUmbrella) {
            return option.name === 'umbrella' && option.labelId && option.labelId === instructions.id;
          }
          return option.name === 'admin';
        });
        if (!(menuForManagement && menuForManagement.menuItems)) {
          this.stateService.blockingError = BlockingErrorType.ManagementRights;
          throw new GripError('Could not find menu items for the management menu.');
        }
        this.menuService.activeMenu = menuForManagement;
        this.menuService.routeToFirstItemInMenu();

        this.managementMenu = {
          name: instructions.label,
          label: instructions.label,
          labelId: instructions.id,
          icon: instructions.icon,
          menuItems: null,
        };
        this.activeOption = this.managementMenu;
      })
    );
  }

  private clearLocalManagement(): void {
    sessionStorage.removeItem('managedType');
    sessionStorage.removeItem('managedId');
  }

  private clearManagement(callback?: (...args: any) => any): void {
    if (!this.stateService.manageTenantId) {
      if (callback) {
        callback();
      }
      return;
    }
    this.clearLocalManagement();
    SessionData.set(SessionDataType.manageTenantId, null);
    SessionData.set(SessionManageDataType.manageTenant, null);
    this.subscriptions.add(
      this.menuDataService.getMenu().subscribe(() => {
        this.stateService.manageTenantId = null;
        this.activeOption = this.realActiveMenu;
        this.menuService.refreshMenu();
        if (callback) {
          callback();
        }
      })
    );
  }
}
