import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataService, LocalizationService, Language, deepClone } from '@kpn-grip-fe/core';
import { API_CONSTANTS } from '../constants/api.constants';
import { GeneralResponse, PortalSettingsModel } from '../models/portal-settings.model';
import { StateService } from './state.service';

@Injectable()
export class PortalSettingsService {
  constructor(private readonly dataService: DataService, private readonly stateService: StateService) {}

  public getSettings(): Observable<PortalSettingsModel> {
    return this.dataService.get(API_CONSTANTS.getGeneral(this.stateService.tenantShortName)).pipe(
      tap((data) => {
        this.handleRedirect(data as GeneralResponse);
        this.handleSettings(data as PortalSettingsModel);
      })
    ) as Observable<PortalSettingsModel>;
  }

  private handleRedirect(data: GeneralResponse): void {
    if ('redirect' in data) {
      window.location.href = window.location.href.replace(window.location.hostname, data.redirect);
    }
  }

  private handleSettings(settings: PortalSettingsModel): void {
    this.stateService.portalSettings = deepClone(settings);
    this.setLanguage(settings.user.preferredLanguage);
    this.setFunctionalities(settings.user.functionalities);
  }

  private setLanguage(languageCode: string): void {
    if (!languageCode) {
      return;
    }
    // Example: "en-US" => "en", "nl-NL" => "nl", "en" => "en"
    const language = languageCode.substr(0, 2) as Language;

    this.stateService.user = {
      language,
    };
    LocalizationService.language = language;
    localStorage.setItem('language', language);
  }

  private setFunctionalities(functionalities: string[] | undefined): void {
    if (functionalities && functionalities.length) {
      this.stateService.user = {
        functionalities: functionalities,
      };
    }
  }
}
