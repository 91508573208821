import { Component, OnDestroy } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  DataService,
  EnvironmentConfigModel,
  GripError,
  ImageService,
  LogoutService,
  ThemeConfigService,
  ThemeService,
  TitleService,
} from '@kpn-grip-fe/core';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MenuAuthInterceptor } from './shared/menu-auth.interceptor';
import { DigitalDataErrorSeverity, DigitalDataErrorType } from './shared/models/digital-data.model';
import { DigitalDataService } from './shared/services/digital-data.service';
import { FaviconService } from './shared/services/favicon.service';
import { LogTraceCookieService } from './shared/services/log-trace-cookie.service';
import { MenuDataService } from './shared/services/menu-data.service';
import { MenuService } from './shared/services/menu.service';
import { MopinionService } from './shared/services/mopinion.service';
import { PortalSettingsService } from './shared/services/portal-settings.service';
import { BlockingErrorType, StateService } from './shared/services/state.service';

export enum GripAppConfigTypes {
  AuthToken = 'AuthToken',
  ColorPrimary = 'ColorPrimary',
  ColorSecondary = 'ColorSecondary',
  Language = 'Language',
}

@Component({
  selector: 'grip-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy {
  public static environmentConfig: EnvironmentConfigModel = undefined;
  public showPage: boolean = false;
  public subscriptions: Subscription = new Subscription();

  constructor(
    public readonly stateService: StateService,
    private readonly themeService: ThemeService,
    private readonly themeConfigService: ThemeConfigService,
    private readonly router: Router,
    private readonly portalSettingsService: PortalSettingsService,
    private readonly titleService: TitleService,
    private readonly menuDataService: MenuDataService,
    private readonly menuService: MenuService,
    private readonly digitalDataService: DigitalDataService,
    private readonly mopinionService: MopinionService,
    private readonly faviconService: FaviconService,
    private readonly logTraceCookieService: LogTraceCookieService
  ) {
    if (!AppComponent.environmentConfig) {
      throw new GripError('Cannot start application: no environmentConfig was provided');
    }
    this.getTenantShortNameFromRoute();
    if (!this.stateService.tenantShortName) {
      return;
    }
    this.setCdnUrl();
    this.setApiUrl();
    MenuAuthInterceptor.apiUrl = `${environment.apiUrl}v3/notifications`;

    this.setLogTraceCookie().then(() => {
      this.getData();
    });

    this.watchLogout();
    this.watchTitleChanges();
    this.setWindowUtility();
  }

  public get themeStyles(): SafeHtml {
    return this.themeService.parseTheme(this.themeConfigService.config());
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setLogTraceCookie(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.subscriptions.add(
        this.logTraceCookieService.getLogTrace().subscribe(
          () => {
            this.logTraceCookieService.setCookie();
            resolve();
          },
          (error) => {
            console.warn('Could not get log trace cookie! Error was: ', error);
            resolve();
          }
        )
      );
    });
  }

  private getData(): void {
    this.stateService.loading = true;
    const apiCallsObservable = this.portalSettingsService.getSettings().pipe(mergeMap(() => this.menuDataService.getMenu()));
    this.subscriptions.add(
      apiCallsObservable.subscribe(
        () => {
          this.stateService.recordActiveSessions();
          this.showPage = true;
          this.stateService.loading = false;
          this.router.initialNavigation();
          this.menuService.initialize();
          this.digitalDataService.initialize();
          this.mopinionService.initialize();
          this.faviconService.initialize();
          MenuAuthInterceptor.bearerToken = this.stateService.authenticationToken;
        },
        (error) => {
          this.digitalDataService.initialize();
          this.digitalDataService.trackError(
            DigitalDataErrorSeverity.high,
            DigitalDataErrorType.system,
            error.error.message,
            error.error.status,
            error.url
          );
          if (error && error.status === 401) {
            this.menuDataService.doLogin(window.location.href);
          } else if (error && error.status === 404) {
            this.stateService.blockingError = BlockingErrorType.NotFound;
          } else {
            this.stateService.blockingError = BlockingErrorType.Generic;
          }
          this.stateService.loading = false;
          this.router.initialNavigation();
          throw new GripError(`Cannot start Grip, as or or more of the basic settings API calls have failed. ${error}`);
        }
      )
    );
  }

  private getTenantShortNameFromRoute(): void {
    const splitPath = window.location.pathname.split('/');
    const tenantShortName = splitPath[2];
    if (!tenantShortName || splitPath[1] !== 'a') {
      if (splitPath[1] === 'deeplink') {
        this.menuDataService.doLogin(null, window.location.pathname.replace('/deeplink', ''));
      } else if (splitPath[1] !== 'a') {
        this.menuDataService.doLogin();
      } else {
        window.location.href = environment.rootIdpUrl;
      }
      return;
    }
    this.stateService.tenantShortName = tenantShortName;
  }

  private setCdnUrl(): void {
    ImageService.cdnUrl = AppComponent.environmentConfig.cdnUrl;
  }

  private setApiUrl(): void {
    if (!environment.production) {
      try {
        const devApiUrl = localStorage.getItem('grip-dev-api-url');
        if (devApiUrl) {
          console.warn('Override active: API URL has been overridden by localStage key grip-dev-api-url with value:', devApiUrl);
          DataService.apiUrl = devApiUrl;
        }
      } catch (error) {
        // noop
      }
    }
  }

  private watchLogout(): void {
    this.subscriptions.add(
      LogoutService.logout$.subscribe(() => {
        this.menuDataService.doLogin(window.location.href);
      })
    );
  }

  private watchTitleChanges(): void {
    this.subscriptions.add(
      this.titleService.title$.subscribe((title) => {
        this.stateService.pageTitle = title;
      })
    );
  }

  private setWindowUtility(): void {
    window.Grip = {
      state: this.stateService,
    };
  }
}
