import { Injectable } from '@angular/core';
import { StateService } from './state.service';

interface Favicon {
  rel: string;
  type: string;
  sizes: string;
  href: string;
}

@Injectable()
export class FaviconService {
  private activeElements: HTMLLinkElement[] = [];

  constructor(private readonly stateService: StateService) {}

  public initialize(): void {
    const favicon: Favicon = {
      rel: 'icon',
      type: 'image/png',
      sizes: '192x192',
      href: this.stateService.tenant.icon ? this.stateService.tenant.icon : 'assets/favicon/kpn-favicon.png',
    };
    this.addLinkElements([this.createLinkElement(favicon)]);
  }

  private addLinkElements(elements: HTMLLinkElement[]): void {
    this.activeElements.forEach((element) => element.parentNode.removeChild(element));
    this.activeElements = [];

    elements.forEach((element) => {
      this.activeElements.push(element);
      document.head.appendChild(element);
    });
  }

  private createLinkElement(favicon: Favicon): HTMLLinkElement {
    const linkElement: HTMLLinkElement = document.createElement('link');
    linkElement.setAttribute('rel', favicon.rel);
    linkElement.setAttribute('type', favicon.type);
    linkElement.setAttribute('sizes', favicon.sizes);
    linkElement.setAttribute('href', `${favicon.href}?v=${Date.now()}`);
    return linkElement;
  }
}
