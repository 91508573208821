import { Component } from '@angular/core';
import { StateService, BlockingErrorType } from '@grip/shared/services/state.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'grip-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent {
  constructor(public readonly stateService: StateService, private readonly translateService: TranslateService) {}
  public get pageTitle(): string {
    let title = this.stateService.pageTitle;
    if (!!this.stateService.blockingError) {
      switch (this.stateService.blockingError) {
        case BlockingErrorType.NotFound:
          title = this.translateService.instant('error__pageTitleNotFound');
          break;
        case BlockingErrorType.Generic:
        default:
          title = this.translateService.instant('error__pageTitle');
          break;
      }
    }
    return title;
  }
}
