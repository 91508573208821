import { HttpMethod } from '@kpn-grip-fe/core';
import { HttpErrorResponse } from '@angular/common/http';

export interface UserProvisioning {
  url: string;
  arrayOfData: UserData[];
  payload: object;
  task: any; // no clue what this does, its from backbone
  action: HttpMethod;
}

export interface UserData {
  id: string;
  description: string;
  state?: UserDataState;
}

export interface UserError {
  statusMessage: string;
  errorDetail: string;
  status: number;
}

export interface ProvisioningMessage {
  data: {
    type: string;
    error?: HttpErrorResponse;
  };
}

export enum UserDataState {
  success = 'success',
  failed = 'failed',
  pending = 'pending',
}

export interface QueueStatus {
  data: {
    failed: number;
    pending: number;
    success: number;
    totalCalls: number;
  };
  statusMessage: SocketStatusMessage;
}

export enum SocketStatusMessage {
  ACCESS_TOKEN_INVALID = 'AccessTokenInvalid',
  ACCESS_TOKEN_EXPIRED = 'AccessTokenExpired',
  ACCESS_TOKEN_MISSING = 'AccessTokenMissing',
  INTERNAL_ERROR = 'InternalError',
  INVALID_PAYLOAD = 'InvalidPayload',
  INVALID_PAYLOAD_PATCH_USER = 'InvalidPayloadPatchUser',
  INVALID_TOPIC = 'InvalidTopic',
  ERROR_PATCH = 'ErrorPatch',
  QUEUE_STATUS = 'QueueStatus',
  QUEUE_STARTED = 'QueueStarted',
}
