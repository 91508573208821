import { MenEnvironmentConfigModel } from './men-environment-config.model.js';

export const environment: MenEnvironmentConfigModel = {
  production: false,
  acceptance: false,
  apiUrl: '',
  cdnUrl: 'https://tst.gripstatic.com',
  bmsApiUrl: '',
  portalUrl: window.location.origin,
  localDevelopment: false,
  userApiUrl: '',
  rootIdpUrl:
    'https://auth.tst.onkpn.com/v2/oidc/idp/authorize?response_type=code&redirect_uri=https%3A%2F%2Fwww.tst.onkpn.com%2Fapi%2Fmenu%2Fv1%2Flogin%2Fcallback&state=%257B%2522domain%2522%253A%2522https%253A%252F%252Fwww.tst.onkpn.com%2522%257D&scope=openid%20offline_access&client_id=6070b1dc-1bd7-4e00-8b79-55df5db96cc0',
  logTraceCookieUrl: 'https://auth.tst.onkpn.com/api/v3/log-trace',
};
