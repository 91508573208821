<grip-square *ngIf="isContainer"></grip-square>
<div *ngIf="!isContainer" class="content-container">
  <iframe
    *ngIf="contentUrl"
    #iframeElement
    [src]="contentUrl"
    class="content-container"
    frameborder="0"
    height="100%"
    width="100%"
  ></iframe>
</div>
