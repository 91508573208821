<div class="notifications">
  <div class="card notifications__card">
    <div *ngIf="loading" class="progress-spinner__container">
      <div class="progress-spinner"></div>
    </div>

    <div *ngIf="!loading && !notifications?.length" class="no-notifications">
      {{ 'notifications__empty' | translate }}
    </div>

    <div class="notification-list" *ngIf="!loading && notifications?.length">
      <div
        *ngFor="let notification of notifications"
        (click)="openNotification(notification)"
        class="notification-list-item"
        [class.notification-list-item--highlighted]="notification === highlightedNotification"
      >
        <div class="notification-list-item__active-marker theme__background--secondary"></div>
        <span class="notification-list-item__title" [class.notification-list-item__title--unread]="!notification.read">{{
          notification.title
        }}</span>
        <span class="notification-list-item__date">{{ notification.created }}</span>
      </div>
    </div>
    <div
      *ngIf="!loading && notifications?.length"
      class="notification-detail"
      [class.notification-detail--filled]="!!highlightedNotification"
    >
      <ng-container *ngIf="highlightedNotification">
        <button
          (click)="openNotification(null)"
          class="notification-detail__back theme__color--secondary-dark"
          data-testid="RWYCIA46TA"
          tabindex="0"
          type="button"
        >
          {{ 'notifications__backToList' | translate }}
        </button>

        <small class="notification-detail__meta"> {{ highlightedNotification.displayName }} • {{ highlightedNotification.created }} </small>
        <h2>{{ highlightedNotification.title }}</h2>

        <div class="notification-detail__body" [innerHTML]="highlightedNotification.body"></div>

        <button
          class="button button--secondary notification-detail__delete"
          data-testid="RWYCIA249A"
          (click)="deleteNotification(highlightedNotification)"
        >
          {{ 'notifications__delete' | translate }}
        </button>
      </ng-container>
    </div>
  </div>
</div>
