import { Injectable } from '@angular/core';
import { DataService } from '@kpn-grip-fe/core';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { StateService } from './state.service';

@Injectable()
export class LogTraceCookieService {
  constructor(
    private readonly dataService: DataService,
    private readonly stateService: StateService,
    private readonly cookieService: CookieService
  ) {}

  public getLogTrace(): Observable<string> {
    if (this.cookieService.get('GripLogTrace')) {
      this.stateService.gripLogTrace = this.cookieService.get('GripLogTrace');
    }
    if (this.stateService.gripLogTrace) {
      return of(this.stateService.gripLogTrace);
    }
    return this.dataService.get(environment.logTraceCookieUrl).pipe(
      map((data: { GripLogTrace: string }) => {
        this.stateService.gripLogTrace = data['GripLogTrace'];
        return data['GripLogTrace'] as string;
      })
    );
  }

  public setCookie(): void {
    this.cookieService.set('GripLogTrace', this.stateService.gripLogTrace, {
      path: '/',
      secure: true,
      sameSite: 'None',
    });
  }
}
