import { SHARED_TRANSLATIONS } from '@grip/shared/translations/shared.translations.keys';
import { defineTranslations } from '@kpn-grip-fe/translations';

/* tslint:disable:max-line-length */
export const SHARED_TRANSLATIONS_NL = defineTranslations<typeof SHARED_TRANSLATIONS>('nl', {
  shared__save: 'Opslaan',
  shared__cancel: 'Annuleer',
  shared__and: 'en',
  shared__delete: 'Verwijderen',
  shared__confirm: 'Bevestig',
  shared__add: 'Toevoegen',
  shared__close: 'Sluiten',
  shared__exit: 'Verlaten',
  shared__saveAndExit: 'Opslaan en verlaten',
  shared__saveAndContinue: 'Opslaan en doorgaan',
  shared__continue: 'Doorgaan',
  square__tileButtonLabel: 'Ga naar',
  sideBar__close: 'Sluit',
  menuTrigger__label: 'Menu',

  error__pageTitle: 'Fout',
  error__pageTitleNotFound: 'Niet gevonden',
  error__title: 'Oeps!',
  error__subtitle: 'Er ging iets mis.',
  error__content: 'Er is een technische fout opgetreden. Neem contact op met uw servicedesk als dit probleem aanhoudt.',
  error__contentNotFound: 'De opgevraagde pagina kon niet worden gevonden. Wellicht bestaat deze niet meer of is hij verplaatst.',
  error__contentManagementRights:
    'Kon het managementverzoek niet afhandelen. Wellicht heeft u onvoldoende rechten bij de verzochte tenant/umbrella om deze management-actie uit te voeren.',
  error__back: 'Ga terug',
  error__knowledgeBase: 'Bezoek Knowledge Base',

  profile__pageTitle: 'Profiel',
  profile__heading: 'Profiel',
  profile__addAnotherAccount: 'Voeg een ander account toe',
  profile__link: 'Mijn account',
  profile__logout: 'Uitloggen',
  profile__changePassword: 'Wachtwoord wijzigen',
  profile__nameLabel: 'Naam',
  profile__lastLoginLabel: 'Vorige keer ingelogd',
  profile__languageLabel: 'Taal',
  profile__languageEnglish: 'Engels (English)',
  profile__languageDutch: 'Nederlands',
  profile__success: 'Uw profielinstellingen zijn met succes bijgewerkt.',
  profile__noPreviousLogin: 'Niet eerder ingelogd.',
  profile__logTraceLabel: 'Support-sessietrackingnummer',

  provisioning__title: 'Wachtrij wijzigen gebruikers',
  provisioning__remove: 'Verwijder wachtrij',
  provisioning__goToDeprovisioningQueue: 'Naar (de)provisioning wachtrij',
  provisioning__deprovisioningQueueInfo:
    'Om meer details te zien en eventuele provisioning-acties te debuggen kunt u naar de (de)provisioning wachtrij.',
  provisioning__executed: '{{callsToExecute}} gebruiker(s) te gaan',
  provisioning__succeeded: '{{succeeded}} gebruikersupdate(s) geslaagd',
  provisioning__failed: '{{failed}} gebruikersupdate(s) gefaald',
  provisioning__cancel: 'Annuleren',

  notifications__delete: 'Verwijderen',
  notifications__backToList: 'Terug naar lijst van notificaties',
  notifications__empty: 'Er zijn geen notificaties voor u.',
});
