import { Component, OnInit } from '@angular/core';
import { StateService, BlockingErrorType } from '@grip/shared/services/state.service';
import { DigitalDataService } from '@grip/shared/services/digital-data.service';
import { DigitalDataErrorSeverity, DigitalDataErrorType } from '@grip/shared/models/digital-data.model';

@Component({
  selector: 'grip-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  public blockingErrorType = BlockingErrorType;

  constructor(public readonly stateService: StateService, private readonly digitalDataService: DigitalDataService) {}

  public ngOnInit(): void {
    switch (this.stateService.blockingError) {
      case BlockingErrorType.ManagementRights:
        this.digitalDataService.trackError(
          DigitalDataErrorSeverity.low,
          DigitalDataErrorType.system,
          'Insufficient rights for management.'
        );
        break;
      case BlockingErrorType.NotFound:
        this.digitalDataService.trackError(DigitalDataErrorSeverity.medium, DigitalDataErrorType.user, 'Page not found');
        break;
      case BlockingErrorType.Generic:
      default:
        this.digitalDataService.trackError(DigitalDataErrorSeverity.medium, DigitalDataErrorType.system, 'Unknown error occured.');
        break;
    }
  }
}
